import { MouseEvent, useCallback, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { Button } from 'components/buttons';
import { type CollaborationInfoForLockBar } from 'components/collaboration';
import ActiveUsers, { getListedSessionUser } from 'components/collaboration/ActiveUsers';
import Text from 'components/text';
import useCreateConvo from 'hooks/useCreateConvo';
import useGetUser from 'hooks/useGetUser';
import { Participant } from 'hooks/useSharedResource';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { User } from 'types';
import { SessionUser } from 'types/sessionUser';
import { getUserIdFromLockedId } from 'utils/lock/lockToken';
import { getUserColor } from 'utils/userColor';

import { Container, MessageContainer, Progress } from './styled';

interface CollaborativeSessionUser extends SessionUser {
  readonly isEditing: boolean;
  readonly isMainEditor: boolean;
}

const NO_COLLABORATION: Partial<CollaborationInfoForLockBar> = {
  collaborating: false,
};

function enrichListedCollaborativeUser(
  participant: Participant<boolean>,
  user: User | undefined,
): CollaborativeSessionUser | undefined {
  const sessionUser = getListedSessionUser(participant, user);
  return (
    sessionUser &&
    Object.freeze({
      ...sessionUser,
      isEditing: participant.state || !!participant.lock,
      isMainEditor: !!participant.lock,
    })
  );
}

function getCollaborativeUserOpacity(user: CollaborativeSessionUser): number {
  return user.status === 'visible' ? 1 : 0.5;
}

function getCollaborativeUserBorderColor(user: CollaborativeSessionUser): string | undefined {
  return user.isEditing && user.status === 'visible' ? getUserColor(user) : undefined;
}

function getCollaborativeUserBorderWidth(user: CollaborativeSessionUser): number {
  return user.isMainEditor ? 4 : 2;
}

interface Props {
  readLock: boolean;
  writeLock: boolean;
  lockedBy: string;
  isCancelled?: boolean;
  isSaving: boolean;
  lockedId?: string;
  isCurrentUser?: boolean;
  ownsLockElsewhere?: boolean;
  collaborationInfo?: CollaborationInfoForLockBar;
  onDone: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onForceUnlock?: () => Promise<void>;
  collapsed?: boolean;
  canUnlock?: boolean;
  disableSave?: boolean;
}

export default function LockedIndicator({
  readLock,
  writeLock,
  lockedBy,
  lockedId,
  isCurrentUser,
  ownsLockElsewhere,
  isCancelled,
  isSaving,
  onDone,
  onCancel,
  onForceUnlock,
  collaborationInfo,
  collapsed,
  canUnlock = true,
  disableSave = true,
}: Readonly<Props>) {
  const {
    others,
    collaborating,
    setCollaborating,
    prepareCancel,
    prepareForceUnlock,
    prepareUnlockOnSave,
    collabLock,
    lockedExclusively,
  } = collaborationInfo ?? NO_COLLABORATION;
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const { getUser } = useGetUser();
  const [waitForConfirmation, setWaitForConfirmation] = useState(false);

  const writeProtected = readLock && !collaborating;
  const editable = writeLock || collaborating;
  const handleChat = useCallback(async () => {
    if (!lockedId) return;
    const userId = getUserIdFromLockedId(lockedId);
    if (!userId) return;
    const user = getUser(userId);
    if (!user) return;

    await createConvo(user, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  }, [createConvo, getUser, lockedId, setIsMessageHubOpen, setSelectedConvoId]);

  const onDoneClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (disableSave) return;

      event.stopPropagation();
      if (lockedId) prepareUnlockOnSave?.(lockedId);
      onDone().catch(() => {});
    },
    [disableSave, onDone, lockedId],
  );

  const onCancelClick = useCallback(() => {
    prepareCancel?.();
    onCancel?.().catch(() => {});
  }, [onCancel, prepareCancel]);

  const onForceUnlockConfirm = useCallback(async () => {
    prepareForceUnlock?.();
    await onForceUnlock?.();
    setWaitForConfirmation(false);
  }, [onForceUnlock, prepareForceUnlock]);

  const onForceUnlockPress = useCallback(() => {
    setWaitForConfirmation(true);
  }, [setCollaborating]);

  const onCollaboratePress = useCallback(() => {
    setCollaborating?.(true);
  }, [setCollaborating]);

  const onEndCollaborationPress = useCallback(() => {
    setCollaborating?.(false);
  }, [setCollaborating]);

  const canCollaborate = !!collabLock;
  return (
    (readLock || writeLock || !!others?.length) && (
      <Container $writeProtected={writeProtected} $editable={editable} $collapsed={collapsed}>
        <Box>
          {writeProtected && (
            <VStack>
              {isCurrentUser ? (
                <Text
                  variant="listItemLabelMedium"
                  color="blackHighEmphasis"
                  truncate
                  style={{ flex: 1 }}
                >
                  You&rsquo;re editing {ownsLockElsewhere ? 'elsewhere' : 'in another pane'}...
                </Text>
              ) : (
                <>
                  <Text
                    variant="listItemLabelMedium"
                    color="blackHighEmphasis"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >{`${lockedBy} is editing...`}</Text>
                  {lockedId && (
                    <Text
                      variant="captionRegularUnderline"
                      color="blackHighEmphasis"
                      onClick={handleChat}
                    >
                      Send a message (Open Chat)
                    </Text>
                  )}
                </>
              )}
            </VStack>
          )}
          {!writeProtected && !!others?.length && (
            <ActiveUsers
              participants={others}
              enrichUser={enrichListedCollaborativeUser}
              getUserBorderColor={getCollaborativeUserBorderColor}
              getUserBorderWidth={getCollaborativeUserBorderWidth}
              getUserOpacity={getCollaborativeUserOpacity}
            />
          )}
        </Box>
        <HStack justifyContent="flex-end" gap="6px">
          {writeLock && (
            <>
              {onCancel && (
                <Button
                  usage="significant"
                  variant="outlined"
                  width={104}
                  onClick={onCancelClick}
                  size="md"
                >
                  Cancel
                </Button>
              )}

              <Button
                usage="white"
                disabled={disableSave}
                onClick={onDoneClick}
                size="md"
                width={104}
              >
                Save
              </Button>
            </>
          )}
          {readLock && onForceUnlock && !collaborating && (
            <ClickAwayListener
              onClickAway={() => {
                setWaitForConfirmation(false);
              }}
            >
              <Button
                style={{ overflow: 'inherit' }}
                usage={waitForConfirmation ? 'danger' : 'warning'}
                variant="outlined"
                size="md"
                disabled={!canUnlock}
                onClick={waitForConfirmation ? onForceUnlockConfirm : onForceUnlockPress}
                title={canUnlock ? undefined : 'You do not have permission to unlock'}
              >
                {waitForConfirmation ? 'Confirm Unlock' : 'Force Unlock'}
              </Button>
            </ClickAwayListener>
          )}
          {readLock &&
            (!isCurrentUser || ownsLockElsewhere) &&
            !collaborating &&
            setCollaborating && (
              <Button
                usage="warning"
                variant="outlined"
                size="md"
                onClick={onCollaboratePress}
                disabled={!canCollaborate}
                title={lockedExclusively ? 'The document is being exclusively edited' : undefined}
              >
                Collaborate
              </Button>
            )}
          {readLock && collaborating && setCollaborating && (
            <Button usage="white" variant="outlined" size="md" onClick={onEndCollaborationPress}>
              Leave Collaboration
            </Button>
          )}
        </HStack>
        {(isSaving || isCancelled) && (
          <MessageContainer>
            <Progress variant="indeterminate" disableShrink size={24} thickness={1} />
            <Text variant="button" color="whiteHighEmphasis">
              {isCancelled ? 'Cancelling' : 'Saving'}
            </Text>
          </MessageContainer>
        )}
      </Container>
    )
  );
}
